import React from 'react';
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown";
import BackgroundImage from "gatsby-background-image";
import Img from "gatsby-image";
import moment from 'moment/min/moment-with-locales';

import Layout from '../components/layout';
import SubpageHeader from "../components/subpage-header/subpage-header";
import Container from "../components/container/container";

import Link from "../components/link";
import slugify from "../utils/slugify";

import '../styles/blog.scss';

export default function BlogArchive({data, pageContext}) {
    moment.locale('pl');
    const { currentPage, numPages } = pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage = currentPage - 1 === 1 ? "" : (currentPage - 1).toString()
    const nextPage = (currentPage + 1).toString()
    return (
        <Layout title="Blog">
            <section className="articles-archive">
                <SubpageHeader title="Blog" subtitle="Archiwum bloga" bg />
                <Container>
                    <div className="content">
                        {
                            data.articles.nodes.map(article => {
                                const articleDate = `${moment(article.published_at).format('LL')}`;
                                return (
                                    <article className="single-article">
                                        <Link to={`/blog/${slugify(article.postTitle)}`}>
                                            <BackgroundImage Tag="div" className="article-thumbnail" fluid={article.postThumbnail.childImageSharp.fluid} />
                                        </Link>
                                        <div className="article-meta">
                                            <span className="article-category">{article.categories.categoryName}</span>
                                            <span className="article-date">{articleDate}</span>
                                        </div>
                                        <Link to={`/blog/${slugify(article.postTitle)}`}>
                                            <h1>{article.postTitle}</h1>
                                        </Link>
                                        <ReactMarkdown source={article.postShortContent} />
                                        <Link className='line-button' to={`/blog/${slugify(article.postTitle)}`}>
                                            Czytaj dalej
                                        </Link>
                                        <div style={{clear: 'both'}} />
                                    </article>
                                )
                            })
                        }
                        <div className="pagination">
                            {!isFirst && (
                                <Link to={`/blog/${prevPage}`} rel="prev">
                                ← Poprzednia strona
                                </Link>
                            )}
                            {!isLast && (
                                <Link to={`/blog/${nextPage}`} rel="next">
                                Następna strona →
                                </Link>
                            )}
                        </div>
                    </div>
                    <div className="sidebar">
                        <div className="sidebar-wrapper">
                            <div className="title">Kategorie</div>
                            <div className="all-categories">
                                <ul>
                                    {data.categories.nodes.map(category => <li key={category.strapiId}><Link to={`/blog/kategoria/${category.simpleCategoryName}`}>{category.categoryName}</Link></li>)}
                                </ul>
                            </div>
                            <div className="title">Najnowsze wpisy</div>
                            <div className="latest-articles">
                                <ul>
                                    {data.latestArticles.nodes.map(article => (
                                        <li key={article.strapiId}>
                                            <Link to={`/blog/${slugify(article.postTitle)}`}>
                                                <Img fixed={article.postThumbnail.childImageSharp.fixed} />
                                                {article.postTitle}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </Layout>
    )
}

export const query = graphql`
    query blogArchiveQuery($skip: Int!, $limit: Int!) {
        articles: allStrapiBlog(limit: $limit, skip: $skip) {
            nodes {
                postTitle
                postShortContent
                published_at
                categories {
                    categoryName
                    simpleCategoryName
                }
                postThumbnail {
                    childImageSharp {
                        fluid(maxWidth: 1024) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                strapiId
            }
        }
        latestArticles: allStrapiBlog(limit: 5) {
            nodes {
                postTitle
                postThumbnail {
                    childImageSharp {
                        fixed(width: 50, height: 50) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
                strapiId
            }
        }
        categories: allStrapiCategories {
            nodes {
            categoryName
            simpleCategoryName
            strapiId
            }
        }
    }
`